<template>
    <div>
        <br>
        <div align="center">
            <span class="text-h4">Modifica lo stato della pratica</span>
        </div>
        <br><br>
        <table class="table">
            <tbody>
                <tr>
                    <td class="domanda">Numero: </td>
                    <td class="risposta">{{getNumeroPratica}}</td>
                </tr>
                <tr>
                    <td class="domanda">Area: </td>
                    <td class="risposta">{{area}}</td>
                </tr>
                <tr>
                    <td class="domanda">Data di creazione: </td>
                    <td class="risposta">{{getDataCreazionePratica}}</td>
                </tr>
                <tr>
                    <td class="domanda">Identificativo unit&agrave; operativa: </td>
                    <td class="risposta">{{getIDUnitaOperativa}}</td>
                </tr>
                <tr>
                    <td class="domanda">Stato corrente della Pratica: </td>
                    <td class="risposta">{{getStatoCorrentePratica}}</td>
                </tr>
                <tr>
                    <td class="domanda">Annotazione corrente sulla Pratica: </td>
                    <td class="risposta">{{annotazione}}</td>
                </tr>
                <tr>
                    <td class="domanda">Nuovo Stato: </td>
                    <td>
                        <q-select outlined v-model="model" :options="getElencoStatiPratica()" label="Seleziona il nuovo stato della pratica" />
                    </td>
                </tr>
                <tr>
                    <td class="domanda">Note: </td>
                    <td>
                        <q-input v-model="note" type="textarea" style="border: 1px SOLID #C0C0C0;"/>
                    </td>
                </tr>
            </tbody>
        </table>
         <br><hr>
        <div class="row justify-center">

            <div class="col-md-4" align="center">
                <QQButton label="Torna al menu" color="blue-grey" icon="undo" size="md"
                    @click.native="onIndietroClicked"
                />
            </div>

            <div class="col-md-4" align="center">
                <QQButton label="Registra cambio stato" color="blue-grey" icon="save" size="md"
                    @click.native="onRegistraNuovoStato"
                />
            </div>

        </div>
        <br><br><br><br><br><br>
    </div>
</template>

<script>
    import { mapState, mapActions } from "vuex";
    import QQButton from "@/components/QQButton"
    import commonLib from "@/libs/commonLib";

    export default {
        name: "CambiaStatoPratica",
        components: {
            QQButton
        },
        data() {
            return {
                tab: 'generali',
                totale_file_allegati: 0,
                options: [
                    "IN ATTESA DI LAVORAZIONE",
                    "PRESA IN CARICO",
                    "IN LAVORAZIONE",
                    "RICHIESTA DI INTERVENTO",
                    "DEFINITA",
                    "ANNULLATA",
                    "IN ATTESA DI CONSEGNA"
                ],
                model: "",
                note: "",
                annotazione: ""
            }

        },
        computed: {
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                formPratica: state => state.gestionePratiche.formPratica
            }),
            getNumeroPratica() {
                if (commonLib.isEmpty(this.formPratica)) return "";
                return this.formPratica.preventivo.id;
            },
            getDataCreazionePratica() {
                if (commonLib.isEmpty(this.formPratica)) return "";
                return this.formPratica.preventivo.create_at;
            },
            getIDUnitaOperativa() {
                if (commonLib.isEmpty(this.formPratica)) return "";
                return this.formPratica.id_unita_operativa;
            },
            getStatoCorrentePratica() {
                if (commonLib.isEmpty(this.formPratica)) return "";
                return this.formPratica.stato_pratica;
            }
        },
        methods: {
            ...mapActions({
                fetchCambiaStatoPratica: "gestionePratiche/fetchCambiaStatoPratica",
                fetchPratiche: "gestionePratiche/fetchPratiche",
                fetchNotePratica: "gestionePratiche/fetchNotePratica",
                fetchDatiPratica: "gestionePratiche/fetchDatiPratica"
            }),
            getElencoStatiPratica() {
                if (commonLib.isEmpty(this.formPratica)) return [];

                return this.options.filter(option => {
                    return option !== this.formPratica.stato_pratica;
                })
            },
            onIndietroClicked() {
                this.$router.push({name :"Pratiche.MenuGestionePratiche"});
            },
            async onRegistraNuovoStato() {
                var num_pratica = this.formPratica.preventivo.id;
                var nuovo_stato = this.model;
                var annotazione = this.note;

                if (nuovo_stato === "") {
                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Se non devi modificare lo stato della pratica allora clicca su "TORNA AL MENU".'
                    });
                    return;
                }

                await this.fetchCambiaStatoPratica({numero_pratica:num_pratica, stato_pratica:nuovo_stato, note:annotazione});

                this.formPratica.stato_pratica = nuovo_stato;
                this.annotazione = annotazione;
                this.note = "";

                //await this.fetchPratiche();

                await this.fetchDatiPratica({ numero_pratica: num_pratica, token: "" });

                this.formPratica.stato_pratica = nuovo_stato;

                this.$router.push({name :"Pratiche.MenuGestionePratiche"});
            },
        },
        async mounted() {
            this.annotazione = "";

            if (!commonLib.isEmpty(this.formPratica)) {
                var num_pratica = this.formPratica.preventivo.id;
                this.annotazione = await this.fetchNotePratica(num_pratica);
            }


        }
    }

</script>

<style scoped>
    td.domanda {
        text-align: right;
        font-style: italic;
        font-weight: bold;
    }
    td.risposta {
        border-bottom: 1px SOLID #c0c0c0;
        padding: 6px;
        width: 60%;
        vertical-align: bottom;
    }
    .table {
        width: 100%;
    }
</style>
